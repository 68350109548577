import { Injectable } from '@angular/core';
import { BaseService } from '@core/BaseService';

@Injectable({ providedIn: 'root' })
export class HolidayService extends BaseService {
  //frontend
  public getData() {
    return this.get('home/GetHoliday');
  }

  //backend
  public getHoliday(query) {
    return this.get('admin/GetHoliday', query);
  }
  public saveHoliday(body) {
    return this.post('admin/SaveHoliday', body);
  }
  public deleteHoliday(body) {
    return this.post('admin/DeleteHoliday', body);
  }
}
