import { Injectable } from '@angular/core';
import { BaseService } from '@core/BaseService';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
  private _module = [
    new ModuleRole('dashboard', 'Dashboard'),
    new ModuleRole('slide', 'Slide'),
    new ModuleRole('seo-page', 'Seo Page'),
    new ModuleRole('introduce', 'Giới thiệu'),
    new ModuleRole('orther-page', 'Trang khác'),
    new ModuleRole('department', 'Chuyên khoa'),
    new ModuleRole('package', 'Gỏi khám'),
    new ModuleRole('docter', 'Bác sỉ'),
    new ModuleRole('feedback', 'Đánh giá'),
    new ModuleRole('news', 'Tin tức'),
    new ModuleRole('recruitment', 'Tuyển dụng'),
    new ModuleRole('partner', 'Đối tác'),
    new ModuleRole('order', 'Đơn hàng'),
    new ModuleRole('booking', 'Đặt lịch'),
    new ModuleRole('contact', 'Liên hệ'),
    new ModuleRole('booking-business', 'Doanh nghiệp đặt lịch'),
    new ModuleRole('account', 'Tài khoản'),
    new ModuleRole('category', 'Danh sách'),
    new ModuleRole('bang-gia-dich-vu', 'Bảng giá dịch vụ'),
    new ModuleRole('vaccine', 'vaccine'),
    new ModuleRole('holiday', 'Ngày nghỉ'),
    new ModuleRole('page', 'Quản lý trang tĩnh'),
    new ModuleRole('advertise', 'Quản lý thông báo'),
    new ModuleRole('email', 'Quản lý Email template'),
    new ModuleRole('config', 'Cấu hình hệ thống'),
    new ModuleRole('introduction', 'Giới thiệu Mobile'),
    new ModuleRole('file', 'QL File Tĩnh'),
    new ModuleRole('seo-config', 'Cấu Hình SEO'),
    // new ModuleRole("option-system", "Cấu hình hệ thống"),
  ];

  private _action = [
    new ActionRole('view', 'Xem trang'),
    new ActionRole('delete', 'Xóa'),
    new ActionRole('edit', 'Cập nhật'),
    new ActionRole('add', 'Thêm mới'),
    new ActionRole('publish', 'Publish'),
  ];

  private _role = [
    new Role(1, 'Admin', {
      dashboard: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      slide: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduce: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'orther-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      department: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      package: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      docter: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      feedback: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      news: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      recruitment: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduction: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      partner: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      order: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      booking: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      file: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      contact: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'booking-business': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      account: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      category: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'bang-gia-dich-vu': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      vaccine: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      holiday: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      page: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      advertise: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      config: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'option-system': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-config': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
    }),
    new Role(2, 'Marketing', {
      dashboard: {
        view: false,
      },
      slide: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduce: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduction: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'orther-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      department: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      package: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      docter: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      news: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      recruitment: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      partner: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      order: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      booking: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      contact: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'booking-business': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      account: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      category: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'bang-gia-dich-vu': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      vaccine: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      holiday: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'option-system': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-config': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
    }),
    new Role(3, 'SEO', {
      dashboard: {
        view: true,
      },
      slide: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'seo-config': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduce: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'orther-page': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      department: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      package: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      docter: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      news: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      recruitment: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      introduction: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      partner: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      order: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      booking: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      contact: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'booking-business': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      account: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      category: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      'option-system': {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
    }),
  ];

  listRole = null;
  constructor() {
    super();
    try {
      var strRole = localStorage.getItem('role');
      if (strRole && strRole != '' && strRole != 'undefined')
        this.listRole = JSON.parse(strRole);
    } catch (error) {
      this.listRole = {};
    }
  }

  public getModuleList() {
    return this._module;
  }
  public getActionList() {
    return this._action;
  }
  public getRole(ID: number) {
    for (let o = 0; o < this._role.length; o++) {
      const element = this._role[o];
      if (element.roleID == ID) {
        return element.role;
      }
    }
    return {};
  }

  public isAccess(module, action = 'view') {
    try {
      return this.listRole[module][action];
    } catch (error) {
      return false;
    }
  }

  public isAccessCheck(module: string, action = 'view'): boolean {
    var mo = this.listRole[module];
    if (mo) return mo[action];
    else {
      return false;
    }
  }
}

export class Role {
  public roleID: number;
  public roleName: string = '';
  public role = {
    dashboard: {
      view: true,
    },
    slide: {
      view: true,
      add: true,
    },
  };

  constructor(roleID: number, roleName = '', role?) {
    this.roleID = roleID;
    this.roleName = roleName;
    if (this.role) this.role = role;
  }

  public addRole(module: ModuleRole, action: ActionRole, access: boolean) {
    // if(this.role)
    // {
    //     if(this.role[module.key])
    //     {
    //         if(this.role[module.key][action.key])
    //         {
    //           this.role[module.key][action.key] = access;
    //         }else{
    //           this.role[module.key][action.key]
    //         }
    //     }else{
    //       this.role[module.key]= {};

    //     }
    // }
    this.role[module.key][action.key] = access;
  }
}

export class ModuleRole {
  public key: string;
  public name: string;
  constructor(key, name = '') {
    this.key = key;
    this.name = name;
  }
}

export class ActionRole {
  public key: string;
  public name: string;
  constructor(key, name = '') {
    this.key = key;
    this.name = name;
  }
}
