<div class="container-fluid p-0">
  <div class="page-background-tt">
    <div class="col-12 text-center page-title-top">
      <h1>{{ 'bookingPage.title' | translate }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Trang chủ</a></li>
          <li class="breadcrumb-item">Đặt lịch</li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<section class="booking-detail py-0">
  <app-block (onInit)="initData()" id="booking">
    <div class="container bg-white py-4">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="booking-content">
            <div class="titlecircle">
              <span class="circle">1</span>
              <h6>{{ 'bookingPage.stepTile1' | translate }}</h6>
            </div>
            <p>
              <strong>{{ 'bookingPage.step' | translate }} 1:</strong> {{ 'bookingPage.stepDescription1' | translate }}
            </p>
            <p>
              <strong>{{ 'bookingPage.step' | translate }} 2:</strong> {{ 'bookingPage.stepDescription2' | translate }}
            </p>
            <p>
              <strong>{{ 'bookingPage.step' | translate }} 3:</strong> {{ 'bookingPage.stepDescription3' | translate }}
            </p>
          </div>
          <div class="content-icon mb-3">
            <h6 class="px-3 py-0 mb-0 text-uppercase">{{ 'bookingPage.bookingBy' | translate }}</h6>
            <div class="booking-call d-flex">
              <a href="mailto:info@simmed.vn" target="_blank"> <img alt="iconMail" src="/assets/images/icon/iconMail.png" style="width: 40px" /></a>
              <a href="viber://add?number=0899166538"><img alt="Đặt hẹn qua viber" src="/assets/images/icon/iconViber.png" style="width: 40px" /></a>
              <a href="http://zalo.me/0899166538" target="_blank"
                ><img alt="Đặt hẹn qua zalo" src="/assets/images/icon/iconZalo.png" style="width: 40px"
              /></a>
              <a href="tel:1900252535"><img alt="Đặt hẹn qua cuộc gọi" src="/assets/images/icon/iconPhone.png" style="width: 40px" /></a>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <div class="booking-content">
            <div class="booking-form">
              <form class="row" #frmSchedule="ngForm" (ngSubmit)="onSubmitSchedule(frmSchedule)" autocomplete="off">
                <div class="col-lg-6 col-md-6 col-12 mb-3">
                  <div class="titlecircle">
                    <span class="circle">2</span>
                    <h6>{{ 'bookingPage.stepTile2' | translate }}</h6>
                  </div>
                  <!-- họ tên -->
                  <app-input
                    minlength="3"
                    required
                    maxlength="250"
                    classRow="mb-2"
                    [(ngModel)]="bookingForm.FullName"
                    name="txtFullName"
                    [name-control]="'bookingPage.fullname' | translate"
                    [label]="'bookingPage.fullname' | translate"
                  >
                  </app-input>
                  <!-- số điện thoại -->
                  <app-input
                    minlength="3"
                    classRow="mb-2"
                    required
                    [pattern]="mobNumberPattern"
                    maxlength="20"
                    [(ngModel)]="bookingForm.MobilePhone"
                    name="txtPhone"
                    [name-control]="'bookingPage.phoneNumber' | translate"
                    [label]="'bookingPage.phoneNumber' | translate"
                  ></app-input>
                  <app-input
                    minlength="3"
                    [pattern]="emailPattern"
                    maxlength="100"
                    classRow="mb-2"
                    [(ngModel)]="bookingForm.Email"
                    name="txtEmail"
                    [name-control]="'bookingPage.email' | translate"
                    [label]="'bookingPage.email' | translate"
                  ></app-input>
                  <app-input
                    type="textarea"
                    minlength="3"
                    classRow="mb-2"
                    required
                    maxlength="100"
                    [(ngModel)]="bookingForm.Notes"
                    name="txtContent"
                    [name-control]="'bookingPage.content' | translate"
                    [label]="'bookingPage.content' | translate"
                  >
                  </app-input>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="titlecircle">
                    <span class="circle">3</span>
                    <h6>{{ 'bookingPage.stepTile3' | translate }}</h6>
                  </div>
                  <div class="row">
                    <div class="col-md-7 col-12 date">
                      <!-- ngày -->
                      <!-- <app-input type="date" required class="novalid-icon" [(ngModel)]="bookingForm.BookingDate"
                        name="txtDate" [name-control]="'bookingPage.bookingDate' | translate"
                        [label]="'bookingPage.bookingDate' | translate" placeholder="dd/mm/yyyy"
                        datetime="yyyy-MM-dd HH:mm:ss" pattern="\d{4}-\d{2}-\d{2}">
                      </app-input> -->
                      <!-- <div class="form-group">
                        <div class="input-group">
                          <input class="form-control" placeholder="dd-mm-yyyy"
                                 name="dp" [(ngModel)]="bookingForm.BookingDate" ngbDatepicker #d="ngbDatepicker">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                          </div>
                        </div>
                      </div> -->
                      <div class="form-group mb-0">
                        <label for="" style="font-size: 0.8rem; font-weight: bold !important; color: #4d4d4d; margin-bottom: 3px !important">
                          {{ 'bookingPage.bookingDate' | translate }}
                          <span class="text-danger ms-1">*</span>
                        </label>
                        <div class="input-group input-date mb-2">
                          <input
                            class="form-control"
                            placeholder="dd/mm/yyyy"
                            #c2="ngModel"
                            name="BookingDate"
                            [minDate]="mindate"
                            [markDisabled]="isDisabledDate"
                            [(ngModel)]="bookingForm.BookingDate"
                            ngbDatepicker
                            #d="ngbDatepicker"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar py-2" (click)="d.toggle()" type="button">
                              <i class="fa-solid fa-calendar-days"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5 col-12">
                      <!-- giờ -->
                      <app-input
                        class="novalid-icon"
                        type="select"
                        required
                        [list]="listTime"
                        [(ngModel)]="bookingForm.TimeStatus"
                        name="slTimeStatus"
                        [name-control]="'bookingPage.bookingTime' | translate"
                        [label]="'bookingPage.bookingTime' | translate"
                        [default]="'bookingPage.select_time.label' | translate"
                      >
                      </app-input>
                    </div>
                  </div>
                  <!-- phòng khám -->
                  <app-input
                    type="select"
                    required
                    [list]="bookingDTO.stores"
                    [(ngModel)]="bookingForm.Store"
                    required
                    name="slStore"
                    [name-control]="'bookingPage.storeName' | translate"
                    classRow="mb-2"
                    [label]="'bookingPage.storeName' | translate"
                    [defaultOption]="customOptionStore"
                    (ngModelChange)="changeStore($event)"
                    [default]="{ value: null, text: 'bookingPage.storeText' | translate }"
                  >
                    <ng-template #customOptionStore let-item>
                      {{ item.storeName }}
                    </ng-template>
                  </app-input>
                  <!-- bác sĩ -->
                  <app-input
                    type="select"
                    [list]="bookingDTO.doctorFromStores"
                    [(ngModel)]="bookingForm.Doctor"
                    name="slDocter"
                    [name-control]="'bookingPage.doctor' | translate"
                    classRow="mb-2"
                    [label]="'bookingPage.doctor' | translate"
                    [defaultOption]="customOptionDocter"
                    [default]="'bookingPage.doctor' | translate"
                  >
                    <ng-template #customOptionDocter let-item>
                      {{ item.doctorNameBooking }}
                    </ng-template>
                  </app-input>
                  <!-- mã capchar -->
                  <label></label>
                  <div class="form-group">
                    <div class="captcha" style="overflow: hidden">
                      <ngx-recaptcha2
                        *ngIf="isBrowser"
                        #captchaElem
                        [siteKey]="siteKey"
                        (reset)="handleReset()"
                        (expire)="handleExpire()"
                        (load)="handleLoad()"
                        (success)="handleSuccess($event)"
                        [useGlobalDomain]="false"
                        [hl]="langCode"
                        name="recaptcha"
                        ngModel
                        required
                        [ngClass]="{ 'is-invalid': frmSchedule.submitted && frmSchedule.controls.recaptcha.invalid }"
                      >
                      </ngx-recaptcha2>
                    </div>
                  </div>
                  <!-- submit -->
                  <div class="button mt-3 text-center text-md-start">
                    <button type="submit" class="btn btn-booking" [disabled]="isLoading">
                      <i class="far fa-calendar-alt"></i> {{ 'buttons.booking' | translate }}
                      <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"> </i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-block>
</section>

<!-- popup -->
<!-- <jw-modal id="custom-modal-1" class="success">
  <div class="jw-modal-body">
    
  </div>
</jw-modal> -->
<ng-template #popSuccess let-modal>
  <div class="modal-body noti-success">
    <span class="fas fa-check-circle"></span>
    <h5 class="title1">{{ 'bookingPage.alertMsg' | translate }}</h5>
    <div class="title2">
      <h6>{{ 'bookingPage.hotline' | translate }}</h6>
      <a href="tel:1900252535" title="Call: 1900 252 535">1900 252 535</a>
    </div>
    <button (click)="modal.close()" (click)="resetData()"><i class="fas fa-times"></i></button>
  </div>
</ng-template>

<!-- warning -->
<!-- <jw-modal id="popup-Alert" class="warning">
  <div class="jw-modal-body">
    
  </div>
</jw-modal> -->

<ng-template #popWarning let-modal>
  <div class="modal-body noti-warning">
    <span class="fas fa-exclamation-triangle"></span>
    <h4 class="title1 p-3">{{ 'bookingPage.notification' | translate }}</h4>
    <h6 class="title2">{{ 'bookingPage.warning' | translate }}</h6>
    <button (click)="modal.close()"><i class="fas fa-times"></i></button>
  </div>
</ng-template>
